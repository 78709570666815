import "./vendor/chosen.jquery";
import "./vendor/jquery.waypoints";
import "./vendor/dom-confetti";
import "./shopify";
import "slick-carousel";

$(function () {
  $("#newsletter-email").on("input", function (e) {
    $("#newsletter-email-submit").attr(
      "href",
      `https://mavenpublishing.us2.list-manage.com/subscribe?u=1e32507f4fc59c0b564aea36b&id=96bf515d51&categorien=lezer&verstuur=Aanmelden&group[8269][4]=4&MERGE0=${e.target.value}`
    );
  });
  $(".logo")
    .on("mouseenter", function () {
      var index = 1;

      this.iid = setInterval(function () {
        $(`.logo-quote-${index}`).addClass("animate");
        index = index + 1;
      }, 750);
    })
    .on("mouseleave", function () {
      $(".logo-quote").removeClass("animate");
      this.iid && clearInterval(this.iid);
    });

  if (!localStorage.getItem("newsletterPopupShown")) {
    setTimeout(function () {
      $(".newsletter-modal").addClass("visible");
      localStorage.setItem("newsletterPopupShown", "true");
    }, 15000);
  }

  $(".newsletter-toggle").on("click", function (evt) {
    var $modal = $(".newsletter-modal");
    if ($modal.hasClass("visible") || $modal.hasClass("hidden")) {
      $modal.toggleClass("visible hidden");
    } else {
      $modal.addClass("visible");
    }
    evt.stopImmediatePropagation();
  });

  $(".hamburger-icon").on("click", function (evt) {
    $(".hamburger-icon").toggleClass("open");
    $(".mobile-menu").toggleClass("active");
    $("body").toggleClass("mobile");
    evt.stopImmediatePropagation();
  });

  $(".search__input").on("keyup", function () {
    $.get($(this).data("endpoint"), { query: $(this).val() }, null, "script");
    return false;
  });

  $(".customer-slider").slick({
    arrows: true,
    slidesToShow: 3,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 1000,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
  if ($("#counter-1").length) {
    var waypoint = new Waypoint({
      element: document.getElementById("counter-1"),
      handler: function (direction) {
        var $el = $(this.element),
          value = $("#customer-count-helper").text();

        $({ percentage: 0 })
          .stop(true)
          .animate(
            { percentage: value },
            {
              duration: 2000,
              step: function () {
                var newVal = Math.round(this.percentage);

                $el.text(newVal);
              },
            }
          )
          .promise()
          .done(function () {
            $el.text(value);
          });
      },
      offset: "bottom-in-view",
    });
  }
  if ($("#counter-2").length) {
    var waypoint = new Waypoint({
      element: document.getElementById("counter-2"),
      handler: function (direction) {
        var $el = $(this.element),
          value = $("#keynote-count-helper").text();

        $({ percentage: 0 })
          .stop(true)
          .animate(
            { percentage: value },
            {
              duration: 2000,
              step: function () {
                var newVal = Math.round(this.percentage);

                $el.text(newVal);
              },
            }
          )
          .promise()
          .done(function () {
            $el.text(value);
          });
      },
      offset: "bottom-in-view",
    });
  }
  if ($("#counter-3").length) {
    var waypoint = new Waypoint({
      element: document.getElementById("counter-3"),
      handler: function (direction) {
        var $el = $(this.element),
          value = $("#company-count-helper").text();

        $({ percentage: 0 })
          .stop(true)
          .animate(
            { percentage: value },
            {
              duration: 2000,
              step: function () {
                var newVal = Math.round(this.percentage);

                $el.text(newVal);
              },
            }
          )
          .promise()
          .done(function () {
            $el.text(value);
          });
      },
      offset: "bottom-in-view",
    });
  }
  if ($(".wysiwyg-content").length) {
    $(".wysiwyg-content iframe").each(function () {
      $(this).wrap('<div class="responsive-iframe"></div>');
    });
  }

  let confetti_colors = [
    "#E68F17",
    "#FAB005",
    "#FA5252",
    "#E64980",
    "#BE4BDB",
    "#0B7285",
    "#15AABF",
    "#EE1233",
    "#40C057",
  ];

  let confettis_conf = [
    // 1
    {
      angle: 270,
      spread: 45,
      startVelocity: 20,
      elementCount: 10,
      decay: 0.7,
      colors: confetti_colors,
    },
    // 2
    {
      angle: 270,
      spread: 90,
      startVelocity: 30,
      elementCount: 30,
      decay: 0.73,
      colors: confetti_colors,
    },
    // 3
    {
      angle: 90,
      spread: 180,
      startVelocity: 40,
      elementCount: 50,
      decay: 0.75,
      colors: confetti_colors,
    },
    // 4
    {
      angle: 90,
      spread: 270,
      startVelocity: 50,
      elementCount: 80,
      decay: 0.77,
      colors: confetti_colors,
    },
    // 5
    {
      angle: 90,
      spread: 360,
      startVelocity: 60,
      elementCount: 150,
      decay: 0.82,
      colors: confetti_colors,
    },
  ];

  $(document).on("click", ".action-koopknop", function () {
    window.confetti(this.parentNode, confettis_conf[1]);
    var self = this;

    setTimeout(function () {
      var f = document.createElement("form");
      f.style.display = "none";
      self.parentNode.appendChild(f);
      f.method = "POST";
      f.action = "https://shop.mavenpublishing.nl/cart/add";
      var v = document.createElement("input");
      v.setAttribute("type", "hidden");
      v.setAttribute("name", "id");
      v.setAttribute("value", $(self).data("variant-id"));
      f.appendChild(v);
      var r = document.createElement("input");
      r.setAttribute("type", "hidden");
      r.setAttribute("name", "return_to");
      r.setAttribute("value", "/cart");
      f.appendChild(r);
      f.submit();
      return false;
    }, 1000);
  });

  function timeout() {
    setTimeout(function () {
      var book_length = $(".stage-small").length;
      var random_number = Math.floor(Math.random() * book_length);
      $(`.stage-small:eq(${random_number})`).toggleClass("animated");
      timeout();
    }, 5000);
  }

  if ($(".stage-small").length) {
    timeout();
  }

  var videos = document.querySelectorAll(".ql-video");
  for (let i = 0; i < videos.length; i++) {
    var embedContainer = document.createElement("div");
    embedContainer.setAttribute("class", "embed-container");
    var parent = videos[i].parentNode;
    parent.insertBefore(embedContainer, videos[i]);
    embedContainer.appendChild(videos[i]);
  }
});

function mail(form) {
  console.log("mail called");
  var name = form.name.value;
  var organisatie = form.organisation ? form.organisation.value : "";
  var phone = form.phone ? form.phone.value : "";
  var date = form.date ? form.date.value : "";
  var subject = form.subject.value;
  var expert = form.expert.value;

  var str = "mailto:evelien@mavenatwork.com?subject=" + subject + "&body=";
  if (name.length > 0) {
    str +=
      "Hallo Evelien, %0D%0A%0D%0AMijn naam is " +
      name +
      " en ik ben geïnteresseerd in " +
      expert +
      ".%0D%0A%0D%0A";
  }
  if (date.length > 0) {
    str += "Beoogde datum: " + date + ", %0D%0A";
  }
  if (organisatie.length > 0) {
    str += "Organisatie: " + organisatie + ", %0D%0A";
  }
  if (phone.length > 0) {
    str += "Telefoonnummer: " + phone + ", %0D%0A";
  }

  str += "%0D%0AMvg,%0D%0A" + name;
  document.getElementById("send").href = str;
}

var slideIndex = 1;

function plusDivs(n) {
  var newIndex = slideIndex + n;
  if (newIndex == 0) return;
  var y = document.getElementsByClassName("movie");
  if (newIndex > y.length) return;
  slideIndex = newIndex;
  showDivs(slideIndex);
}

function showDivs(n) {
  var i;
  var x = document.getElementsByClassName("youtube");
  if (x == null) return;
  if (x.length < 1) return;
  var youtube = x[0];
  var y = document.getElementsByClassName("movie");
  if (n > y.length) return;
  if (n < 1) return;

  var scrollX = (n - 1) * (506 + 24) + (n - 1) * 24;
  youtube.scrollTo(scrollX, 0);
}
